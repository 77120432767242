import React, { useState, useEffect } from 'react';
import './custom.css';
import Loading from './Loading';
import { getApiUrl } from './ApiUtil';
import Cookies from 'js-cookie';
import AALogo from './icons/aa-logo-full-color-horz.png'
import AAShadow from './icons/shadow12-up.png'
import AuthIcon from './icons/i-authorizer.png'




export function Main() {


    const absenceReasons = {
        IOD: "On-the-job injury (OJI)",
        SK: "Sick",
        FL: "Intermittent Family Leave (FMLA)",
        OT: "Other (jury duty, suspended)"
    };
    const apiUrl = getApiUrl();

    const [employeeLocation, setemployeeLocation] = useState('');
    const [submitInProgress, setsubmitInProgress] = useState(false);

    const [absenceReason, setAbsenceReason] = useState('');

    const [visibleFormOne, setvisibleFormOne] = useState(true);
    const [visibleFormTwo, setvisibleFormTwo] = useState(false);
    const [visibleFinalSubmit, setvisibleFinalSubmit] = useState(true);

    const [dupeError, setdupeError] = useState('');
    const [loading, setLoading] = useState(true);

    const [authOptional, setauthOptional] = useState('');

    const [authObject, setauthObject] = useState({});
    const [authOptionalEmail, setauthOptionalEmail] = useState('');
    const [absenceArray, setaabsenceArray] = useState([]);


    const [empObject, setempObject] = useState({});
    const [empOptionalEmail, setempOptionalEmail] = useState('');

    const [managerObject, setmanagerObject] = useState({});
    const [nonManagerAuth, setnonManagerAuth] = useState(false);



    const [startDate, setstartDate] = useState('');
    const [endDate, setendDate] = useState('');
    const initialValues = { authOptionalEmail: "", empOptionalEmail: "", travelDate: "", routing1: "", routing2: "", freeRouting: false, roundTrip: false, travelReason: "", validDays:"" };
    const [formValues, setFormValues] = useState(initialValues);
    const [initialEmail, setinitialEmail] = useState("");
    const [initialSubmit, setinitialSubmit] = useState(true);
    const [firstSubmit, setfirstSubmit] = useState(true)
    const [validDays, setValidDays] = useState('');

    const [absenceValues, setabsenceValues] = useState({
        absenceReason: '',
        specialConsideration: '',
        webDesc: ''
    });



    useEffect(() => {
        fetchAuthEmployeeDetails();
        fetchAbsenceReasons();

    }, []);

    useEffect(() => {
        if (firstSubmit === false) {
            setvisibleFormOne(false);
            setvisibleFormTwo(true);
        }
    }, [firstSubmit]);

    async function fetchAuthEmployeeDetails() {
        const response = await fetch(`${apiUrl}/data/initial`, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('id_token')}`,
                'X-Access-Token': Cookies.get('access_token')
            }
        });
        if (window.location.origin.includes('stage') || window.location.origin.includes('test') || window.location.origin.includes('localhost')) {
            console.log(response, response.status)
        }
        if (response.status === 404) {
            window.location.replace('/UserNotFound');
            return;
        }
        if (response.status === 401) {
            Cookies.remove('id_token');
            Cookies.remove('access_token');
            Cookies.remove('refresh_token');
            window.location.reload();
            return;
        }
        const data = await response.json();
        if (window.location.origin.includes('stage') || window.location.origin.includes('test') || window.location.origin.includes('localhost')) {
            console.log(data);

        }

        setauthObject(data);
        setLoading(false);
        return data;
    };

    async function fetchAbsenceReasons() {
        const response = await fetch(`${apiUrl}/absence`, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('id_token')}`

            }
        });
        console.log(response, response.status)
        if (response.status === 404) {
            window.location.replace('/UserNotFound');
            return;
        }
        if (response.status === 401) {
            Cookies.remove('id_token');
            Cookies.remove('access_token');
            Cookies.remove('refresh_token');
            window.location.reload();
            return;
        }
        const data = await response.json();
        if (window.location.origin.includes('stage') || window.location.origin.includes('test') || window.location.origin.includes('localhost')) {
            console.log(data);

        }
        setaabsenceArray(data);
        return data;
    };

    const handleTempLoginNumberChange = (event) => {
        const { value } = event.target;
        if (value === "" || (/^\d+$/).test(value)) {
            // Set state only if value is empty or all characters are digits and if the length of value is not more than 6
            if (value.length <= 6) {
                setauthObject(authObject => ({
                    ...authObject,
                    employeeNumber: value
                }));
            }
        }
    };

    const handleEmployeeNumberChange = (event) => {
        const { value } = event.target;
        if (value === "" || (/^\d+$/).test(value)) {
            // Set state only if value is empty or all characters are digits and if the length of value is not more than 6
            if (value.length <= 6) {
                setempObject(empObject => ({
                    ...empObject,
                    employeeNumber: value
                }));
            }
        }
    };

    const [absenceReasonText, setAbsenceReasonText] = useState("");

    const handleAbsenceReasonChange = (event) => {
        const selectedValue = event.target.value;
        setAbsenceReason(selectedValue);
        setAbsenceReasonText(absenceReasons[selectedValue]);
    };

    const handleTravelReasonChange = (event) => {
        setFormValues.travelReason(event.target.value);
    };

    const handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleValidDaysChange = (e) => {
        const { value } = e.target;

        if (value !== '' && (isNaN(value) || Number(value) < 1)) {
            return;  // Prevent invalid input
        }

        setValidDays(value);
    };


    const sendEmail = async (recipientEmail, subject, body) => {
        const emailData = {
            RecipientEmail: recipientEmail,
            Subject: subject,
            Body: body,
        };

        try {
            const response = await fetch(`${apiUrl}/mail/send`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('id_token')}`

                },
                body: JSON.stringify(emailData),
            });

            if (response.ok) {
                console.log('Email sent successfully to', recipientEmail);
            } else {
                console.error('Error sending email to', recipientEmail, ':', response.statusText);
            }
        } catch (error) {
            console.error('Error sending email to', recipientEmail, ':', error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (firstSubmit === true) {
            setdupeError("");
            if (!['0001', '0002', '0003', '0004', '0503'].includes(authObject.personnelSubArea)) {
                window.alert("Only management employees may submit travel authorizations. Please contact your manager or director.");
            }
            if (empObject.employeeNumber === undefined || empObject.employeeNumber === "") {
                window.alert("Please enter an employee number.");
                return;
            }
            if (absenceReason === "" || absenceReason === undefined) {
                window.alert("Please select a reason for the employee's absence from work.");
                return;
            }
            if (authObject.employeeNumber === empObject.employeeNumber) {
                setdupeError('You cannot authorize your own travel. Please consult your manager or director.')
                return;
            }

            getEmpData();
        } else {
            console.log(formValues);
            if (submitInProgress) {
                return;
            }
            const travelDate = new Date(formValues.travelDate);
            const currentDate = new Date();
            const sixMonthsAhead = new Date();
            currentDate.setDate(currentDate.getDate() - 1);
            console.log(travelDate, currentDate, sixMonthsAhead)
            sixMonthsAhead.setMonth(currentDate.getMonth() + 6);
            if (formValues.travelDate === "" || formValues.travelDate === null) {
                window.alert("Please enter a valid travel date.")
                setsubmitInProgress(false);
                return;
            }
            if (travelDate < currentDate) {
                window.alert("Travel date cannot be in the past.");
                setsubmitInProgress(false);
                return;
            }
            if (travelDate > sixMonthsAhead) {
                window.alert("Travel date cannot be more than 6 months from now.");
                setsubmitInProgress(false);
                return;
            }
            if (formValues.validDays === "" || formValues.validDays === null || formValues.validDays <= 0) {
                window.alert("At least 1 day must be entered to authorize travel.")
                setsubmitInProgress(false);
                return;
            }
            if (formValues.validDays >= 190) {
                window.alert("A maximum of 190 days can be authorized at one time. Please change the number of days")
                setsubmitInProgress(false);
                return;
            }
            if (formValues.freeRouting === false) {
                if (formValues.routing1 === "" || formValues.routing2 === "") {
                    window.alert("Please enter the city codes where the employee will be traveling.")
                    setsubmitInProgress(false);
                    return;
                }
            }
            if (absenceValues.absenceReason === "") {
                window.alert("Please enter a reason for travel");
                setsubmitInProgress(false);
                return;
            }
            if (absenceValues.webDesc === 'Special Consideration:' && absenceValues.specialConsideration === "") {
                window.alert("Please enter a reason for travel");
                setsubmitInProgress(false);
                return;
            }
            setsubmitInProgress(true);
            let travelReason = absenceValues.webDesc;
            if (absenceValues.webDesc === 'Special Consideration:') {
                travelReason = `Special Consideration: ${absenceValues.specialConsideration}`
            }
            let authEmail = authObject.businessEmail;
            let empEmail = empObject.businessEmail;
            if (formValues.authOptionalEmail !== "") {
                authEmail = formValues.authOptionalEmail;
            }
            if (formValues.empOptionalEmail !== "") {
                empEmail = formValues.empOptionalEmail;
            }

            let emailBody, emailSubject;
            let emailRecipients = `${authEmail}, ${empEmail}`;
            let roundTrip = "";
            let roundTripStatus = "N";
            if (formValues.roundTrip === true) {
                roundTrip = "(Round-Trip)"
                roundTripStatus = "Y";
            }
            let wc = false;
            if (absenceReason === 'IOD') {
                wc = true;
            }
            function addDaysToDate(date, days) {
                const result = new Date(date);
                result.setDate(result.getDate() + days);
                result.setUTCHours(0, 0, 0, 0);
                return result.toISOString();
            }
            let validDays = parseInt(formValues.validDays, 10);
            let travelEnd = addDaysToDate(travelDate, validDays);
            let formattedtravelDate = travelDate.toISOString();
            let recordJson = {
                "emp_id": parseInt(empObject.employeeNumber),
                "auth_emp_id": parseInt(authObject.employeeNumber),
                "absence_reason": absenceReason,
                "travel_reason": parseInt(absenceValues.absenceReason),
                "reason_other": absenceValues.specialConsideration,
                "auth_name": `${authObject.firstName} ${authObject.lastName}`,
                "auth_level": authObject.personnelSubArea,
                "auth_email": authEmail,
                "emp_subarea": empObject.positionTitle,
                "emp_status": empObject.employeeStatus,
                "emp_name": `${empObject.firstName} ${empObject.lastName}`,
                "auth_title": authObject.positionTitle,
                "emp_location": empObject.location,
                "emp_email": empEmail,
                "travel_start_date": formattedtravelDate,
                "travel_end_date": travelEnd,
                "orig_city": formValues.routing1,
                "dest_city": formValues.routing2,
                "round_trip": roundTripStatus,
                "auth_status": "A",
            }

            if (window.location.origin.includes('stage') || window.location.origin.includes('test') || window.location.origin.includes('localhost')) {
                console.log(recordJson)
                console.log(travelEnd.type, formValues.travelDate.type);
            }
            console.log("inserting to table")

            let emailID = '';

            try {
                const response = await fetch(`${apiUrl}/record/travelrequest`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${Cookies.get('id_token')}`
                    },
                    body: JSON.stringify(recordJson)
                });
                if (!response.ok) {

                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                emailID = data.id;
                console.log('Travel request successfully created:', data.id);
            } catch (error) {
                console.error('Error creating travel request:', error.message);
                window.alert('Error creating travel request: ' + error.message);
                setsubmitInProgress(false);
                return;
            }

            if (nonManagerAuth) {
                emailRecipients += `, ${managerObject.businessEmail}`;
            }
            let travelAuthorized;

            if (formValues.freeRouting === true) {
                travelAuthorized = "Unlimited routing";
            } else {
                travelAuthorized = `${formValues.routing1} to ${formValues.routing2}`;
            }
            if (wc === false) {


                emailBody = `
        Travel Authorization #: ${authObject.employeeNumber}
        Employee: ${empObject.lastName}, ${empObject.firstName}
        Employee #: ${empObject.employeeNumber}
        Employee Location: ${empObject.location}
        Reason for Travel: ${travelReason}
        Travel Authorized: ${travelAuthorized} on ${formValues.travelDate} for ${validDays} days ${roundTrip}


        Authorized by: ${authObject.lastName}, ${authObject.firstName}
        Email sent to: ${emailRecipients}
    `;

                emailSubject = `Travel While Absent: ${empObject.firstName} ${empObject.lastName} on ${formValues.travelDate} ( ${emailID} ) `;
            } else {
                emailRecipients += `, DL_Workers_Compensation@aa.com`;

                emailSubject = `Travel Request Pending: ${empObject.employeeNumber} ${empObject.firstName} ${empObject.lastName} on ${formValues.travelDate}  ( ${emailID} ) `;
                emailBody = `** This request is under review by Workers Compensation. **
                Injury Manager: Unknown/Not Assigned (DL_Workers_Compensation@aa.com)

                Travel should not occur until you receive notification from Workers' Compensation. This request will be reviewed within 2 business days (Monday-Friday). Workers Compensation will notify you of this request's status by email. For A9-qualifying travel, as well as for those who are injured and need to return home the same day or the following day, team members may travel immediately.


                Travel Request #: ${emailID}
                Employee: ${empObject.lastName}, ${empObject.firstName}
                Employee # ${empObject.employeeNumber}
                Employee Location: ${empObject.location}
                Reason for Travel: ${travelReason}
                Travel Requested: ${travelAuthorized} on ${formValues.travelDate} for ${validDays} days ${roundTrip}

                Request submitted by: ${authObject.lastName}, ${authObject.firstName}
                Email sent to: ${emailRecipients}`
            }
            if (window.location.origin.includes('stage') || window.location.origin.includes('test') || window.location.origin.includes('localhost')) {
                console.log(emailSubject, emailBody);
                console.log(formValues);
            }

            try {
                if (window.location.origin.includes('stage') || window.location.origin.includes('test') || window.location.origin.includes('localhost')) {
                    await sendEmail(authEmail, emailSubject, emailBody);
                    await sendEmail(empEmail, emailSubject, emailBody);
                    if (wc === true) {
                        console.log(`An email would be sent to DL_Workers_Compensation@aa.com if not in Test/Stage environment`);
                    }

                } else {
                    await sendEmail(empEmail, emailSubject, emailBody);
                    await sendEmail(authEmail, emailSubject, emailBody);
                    if (wc === true) {
                        await sendEmail('DL_Workers_Compensation@aa.com', emailSubject, emailBody);
                    }

                }

                if (nonManagerAuth) {
                    if (window.location.origin.includes('localhost') || window.location.origin.includes('test') || window.location.origin.includes('stage')) {
                        console.log(`An email would be sent to ${managerObject.businessEmail} if not in Test/Stage environment`);
                    } else {
                        await sendEmail(managerObject.businessEmail, emailSubject, emailBody);
                    }
                }
            } catch (error) {
                window.alert('Error sending email: ' + error.message);
                console.error('Error sending email:', error.message);
                setsubmitInProgress(false);
                return;
            }
            setvisibleFinalSubmit(false);
            setsubmitInProgress(false);
            window.alert("Travel authorization completed");
        }
    };
    async function sendRecord(recordJson) {
        console.log("inserting to table")
        try {
            const response = await fetch(`${apiUrl}/record/travelrequest`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('id_token')}`
                },
                body: JSON.stringify(recordJson)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            console.log('Travel request successfully created:', data.id);
        } catch (error) {
            console.error('Error creating travel request:', error.message);
        }
    }
    const handleChangeAbsenceReason = (event) => {
        const sortOrder = event.target.value;
        const webDesc = event.target.getAttribute('data-web-desc');
        setabsenceValues({
            ...absenceValues,
            absenceReason: sortOrder,
            webDesc: webDesc
        });
    };
    const handleChangeFreeRouting = (event) => {
        setFormValues({
            ...formValues,
            freeRouting: event.target.checked
        });
    };

    const handleSpecialConsiderationChange = (event) => {
        const specialConsideration = event.target.value;
        setabsenceValues({
            ...absenceValues,
            specialConsideration: specialConsideration
        });
    };
    async function getEmpData() {
        const response = await fetch(`${apiUrl}/data/${empObject.employeeNumber}`, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('id_token')}`
            }
        });
        console.log(response, response.status)
        if (!response.ok) {
            if (response.status === 404) {
                setdupeError(`Employee #${empObject.employeeNumber} was not found`);
            } else {
                setdupeError(`An application error occurred. Please try again later.`);
            }
        } else {
            setfirstSubmit(false);
            console.log(response, response.status, 'response is ok')
            const data = await response.json();
            const managerNumber = parseInt(data.reportTo);
            if (managerNumber !== null && managerNumber !== authObject.employeeNumber) {
                const response = await fetch(`${apiUrl}/data/${managerNumber}`, {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get('id_token')}`

                    }
                });
                const managerData = await response.json();
                setnonManagerAuth(true);
                setmanagerObject(managerData);
            }
            setempObject(data);
            setinitialEmail(data.businessEmail);
            setinitialSubmit(false);
            return data;
        }
    }
    if (loading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    fontSize: '48px',
                }}
            >
                Loading Travel Authorization Site...
            </div>
        );
    } else {
        return (
            <div >
                <div className="travel-authorization-form">
                    <img src={AALogo} alt="AALogo" className="AALogo"></img>

                    <div></div>
                    <img src={AAShadow} alt="AA Shadow" className="AAShadow"></img>

                    <div id="formBody">
                    <p className="authTitle" style={{ fontWeight: 'Bold' }}>Authorization to Travel While Absent from Work</p>
                    {firstSubmit && (
                            <><p className="bodyCopy">Prior to team member travel, review the information found on the <a href="https://travelhelp.aa.com/app/page/fee56ed7-2478-4907-831f-543bb17f2a31" target="_blank"> Travel while absent or on leave of absence (LOA)</a> page in the <a href="https://Travelhelp.aa.com" target="_blank">Travel Guide</a>. Please open a case with <a href="https://jetnet.aa.com/sites/team-member-services" target="_blank">Team Member Service Center</a> if you have any additional questions.</p><div className="bodyCopy">If the team member is on an Injury Leave of Absence, please contact Workers' Compensation, who will review the travel request.</div></>

                    )}
                    {!firstSubmit && absenceReason === 'IOD' && (
                        <><p>
                            This form should be completed by the supervisor of the team member currently off work due to an on-the-job injury (OJI).
                        </p>

                         <p style={{ color: 'red' }}>
                            This travel authorization request will be reviewed by Workers' Compensation, taking into consideration the team member's medical restrictions and their ability to travel as determined by their Workers' Compensation treating physician.

                            Workers' Compensation will then notify the leader submitting this form within two business days (Monday through Friday) along with details about the restrictions, duration, and the date of the next office visit.
                            </p>
                            <p style={{ fontWeight: 'Bold' }}>Exceptions when team members may travel immediately:</p>
                            <ul>
                                <li>For A9-qualifying travel, please allow the team member to travel immediately. </li>
                                <li>Team members who are injured and need to return home the same day or the following day. </li>
                            </ul>

                        </>
                    )}

                    {!firstSubmit && absenceReason === 'SK' && (
                        <div>All manager authorizations may be reviewed by your department leadership.</div>
                    )}
                    <br />

                    <div class="container">
                        <div class="title">Authorized By</div>
                        <div class="content">
                            <div class="image">
                                <img src={AuthIcon} alt="Auth Logo" className="AuthIcon"></img>
                            </div>
                            <div class="text-content">
                                <div class="name">
                                    <div>Name: <span id="authVariables">{authObject.firstName ? authObject.firstName + ` ` + authObject.lastName : ""}</span></div>
                                </div>
                                <div class="placeholders">
                                    <div>Employee #: <span id="authVariables">{authObject.employeeNumber ? authObject.employeeNumber : ""}</span></div>
                                    <div>Title: <span id="authVariables">{authObject.positionTitle ? authObject.positionTitle : ""}</span></div>
                                    <div>Job Level: <span id="authVariables">{authObject.personnelSubArea ? authObject.personnelSubArea : ""}</span></div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="authForm" ></div>
                    <div>
                        <p style={{ color: 'red' }} >{dupeError || '\u00A0'}</p>

                        {visibleFormOne && (
                            <table id="casesBox">
                                <thead>
                                    <tr>
                                        <th colSpan="2" className="tableheader">
                                            Travel Authorization:
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr id="caseRow">
                                        <td className="authForm" colspan="2">
                                            <form onSubmit={handleSubmit}>
                                                <label>
                                                    Employee #:<span> </span>
                                                    <input type="text" id="inputBox" value={empObject.employeeNumber} onChange={handleEmployeeNumberChange} />
                                                </label>
                                            </form>
                                        </td>
                                    </tr>

                                    <tr id="caseRow">
                                        <td className="authForm" colspan="2">
                                            <form onSubmit={handleSubmit}>
                                                <label>
                                                    Reason employee is absent from work:
                                                    <br />
                                                    <input type="radio" name="absenceReason" value="IOD" onChange={handleAbsenceReasonChange} /> On-the-Job Injury <br />
                                                    <input type="radio" name="absenceReason" value="SK" onChange={handleAbsenceReasonChange} /> Sick<br />
                                                    <input type="radio" name="absenceReason" value="FL" onChange={handleAbsenceReasonChange} /> Intermittent Family Leave (FMLA)<br />
                                                    <input type="radio" name="absenceReason" value="OT" onChange={handleAbsenceReasonChange} /> Other (jury duty, suspended)
                                                </label>
                                            </form>
                                        </td>
                                    </tr>


                                </tbody>
                            </table>
                        )}
                        <div className="authForm" ></div>
                        {visibleFormTwo && (
                            <table id="casesBox">
                                <thead>
                                    <tr>
                                        <th colSpan="2" className="tableheader">
                                            Travel Authorization:
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr id="caseRow">
                                        <td className="authForm" >
                                            Employee:
                                        </td>
                                        <td className="center">
                                            {empObject.employeeNumber} | {empObject.firstName} {empObject.lastName} | {empObject.positionTitle}
                                        </td>
                                    </tr>
                                    <tr id="caseRow">
                                        <td className="authForm">
                                            Employee's Email:
                                        </td>
                                        <td className="center">
                                            <input name="empOptionalEmail" id="inputBox" type="text" value={formValues.empOptionalEmail} onChange={handleChange} />

                                            <text> (optional)</text>
                                        </td>
                                    </tr>
                                    <tr id="caseRow">
                                        <td className="authForm">
                                            Absence Reason:
                                        </td>
                                        <td className="center">
                                            {absenceReasonText}
                                        </td>
                                    </tr>
                                    <tr id="caseRow">
                                        <td className="authForm">
                                            Travel Date:
                                        </td>
                                        <td className="center">
                                            <input type="date" id="inputBox" name="travelDate" value={formValues.travelDate} onChange={handleChange}></input>  Authorization valid for <input id="inputBox" name="validDays" type="number" value={formValues.validDays} onChange={handleChange} min="1"></input> days
                                        </td>
                                    </tr>
                                    <tr id="caseRow">
                                        <td className="authForm">
                                            Routing:
                                        </td>
                                        <td className="center">
                                                <input name="routing1" id="inputBox" value={formValues.routing1} onChange={handleChange}></input> to <input name="routing2" id="inputBox" value={formValues.routing2} onChange={handleChange}></input> <br></br>(Use city codes; do not include connecting cities) <br></br> {absenceReason !== 'IOD' && (<><b>-OR-</b><br></br> <input type="checkbox" value={formValues.freeRouting} onChange={handleChangeFreeRouting}></input> Allow trips anywhere systemwide during the authorization period</>)}
                                        </td>
                                    </tr>
                                    <tr id="caseRow">
                                        <td className="authForm">
                                            Round Trip?
                                        </td>
                                        <td className="center">
                                            <input type="checkbox" name="roundTrip" value={formValues.roundTrip} onChange={handleChange}></input> Round Trip Authorized 
                                        </td>
                                    </tr>
                                    <tr id="caseRow">
                                        <td className="authForm">Reason for Travel:</td>
                                        <td className="center">
                                            <label>
                                                {absenceReason === 'IOD' && (
                                                    <>
                                                        {absenceArray.filter(item => item.absence_type.trim() === 'IOD').map(item => (
                                                            <React.Fragment key={item.id}>
                                                                <input
                                                                    type="radio"
                                                                    name="absenceReason"
                                                                    value={item.sort_order}
                                                                    data-web-desc={item.web_desc}
                                                                    onChange={handleChangeAbsenceReason}
                                                                />
                                                                {item.web_desc}
                                                                {item.web_desc.trim() === 'Exceptional circumstances:' && (
                                                                    <input
                                                                        type="text"
                                                                        id="inputBox"
                                                                        name="specialConsideration"
                                                                        data-web-desc={item.web_desc}
                                                                        onChange={handleSpecialConsiderationChange}
                                                                        value={absenceValues.specialConsideration || ''}
                                                                    />
                                                                )}
                                                                <br />
                                                            </React.Fragment>
                                                        ))}
                                                    </>
                                                )}
                                                {absenceReason !== 'IOD' && (
                                                    <>
                                                        {absenceArray.filter(item => item.absence_type.trim() === 'SK').map(item => (
                                                            <React.Fragment key={item.id}>
                                                                <input
                                                                    type="radio"
                                                                    name="travelReason"
                                                                    value={item.sort_order}
                                                                    data-web-desc={item.web_desc}
                                                                    onChange={handleChangeAbsenceReason}
                                                                />
                                                                {item.web_desc}
                                                                {item.web_desc.trim() === 'Special Consideration:' && (
                                                                    <input
                                                                        id="inputBox"
                                                                        type="text"
                                                                        name="specialConsideration"
                                                                        onChange={handleSpecialConsiderationChange}
                                                                        data-web-desc={item.web_desc}
                                                                        value={absenceValues.specialConsideration || ''}
                                                                    />
                                                                )}
                                                                <br />
                                                            </React.Fragment>
                                                        ))}
                                                    </>
                                                )}
                                            </label>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                        )}
                        <div className="authForm"></div>

                    </div>
                            <p>For guidance on traveling while absent, please refer to the <a href="https://travelhelp.aa.com/app/page/fee56ed7-2478-4907-831f-543bb17f2a31" target="_blank">Travel Guide</a></p>


                    <div></div>
                    {visibleFinalSubmit && (
                        <input className="save-btn" type="submit" value="Submit" disabled={submitInProgress} onClick={handleSubmit} />
                    )}

                    <div></div>
                    <footer class="col-xs-12 col-sm-12 left-spaces" id="PV">
                        <div class="footer-group clearfix">
                            <div class="first-column">
                                    <a class="padd-lft-5" href="https://jetnet.aa.com/sites/policy-center/knowledgearticle/286526/privacy-policy-for-american-airlines-team-members" target="_blank">Privacy Policy <span class="icon-newpage"></span></a>
                            </div>
                            <div class="second-column">&copy; American Airlines 2024</div>
                        </div>
                    </footer>
                </div>

                </div>
            </div>


        );
    }
}
export default Main;